<template>
  <div>
    <GlobalInfoBar title="代理商管理"/>
    <GlobalChunk
        :padding="[20,20,20,20]">
      <GlobalForm
          :form-item-list="formItemList"
          :inline="true"
          round
          @handleConfirm="query"
          confirmBtnName="查询"
      >
        <div slot="AddSlot">
<!--          <el-button size="small" type="primary" icon="el-icon-plus" @click="addAgent">添加代理商</el-button>-->
        </div>
      </GlobalForm>

      <GlobalTable
          ref="GlobalTable"
          :columns="tableColumns"
          :data="tableData"
          :currentPage="curPage"
          :total="totalElements"
          @handleCurrentChange="currentChange">
        <el-table-column label="省市区" slot="region" align="center">
          <template slot-scope="{ row }">
            {{ row.province_name && row.city_name && row.district_name ? `${row.province_name}/${row.city_name}/${row.district_name}` : '--' }}
          </template>
        </el-table-column>

<!--        <el-table-column label="启用/禁用" slot="is_open" align="center">-->
<!--          <template slot-scope="{ row }">-->
<!--            <el-switch v-model="row.is_open" :active-value="1" :inactive-value="0" @change="switchChange(row)"></el-switch>-->
<!--          </template>-->
<!--        </el-table-column>-->

<!--        <el-table-column label="操作" slot="operation" align="center">-->
<!--          <template slot-scope="{ row }">-->
<!--            <el-button @click="editRow(row)" type="primary" size="mini">编辑</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->

      </GlobalTable>
    </GlobalChunk>
  </div>
</template>

<script>
import {agentHandle, agentList} from "../../../utils/request/changePhone";

export default {
  name: "index",
  data() {
    return {
      formItemList: [
        {
          key: 'agent_name',
          type: 'input',
          labelName: '代理商名称:',
          placeholder: '请输入代理商名称'
        },
        {
          key: 'phone',
          type: 'input',
          labelName: '联系电话:',
          placeholder: '请输入联系电话'
        }
      ],
      tableColumns: [
        {label: "所属渠道", prop: "pass_name"},
        {label: "代理商名称", prop: "agent_name"},
        {label: "联系电话", prop: "phone"},
        {slotName: "region"},
        {label: "详细地址", prop: "address"},
        {slotName: "is_open"},
        {slotName: "operation"},
      ],
      tableData: [],
      curPage: 1,
      totalElements: 0,
      agent_name: '',
      phone: ''
    }
  },
  mounted() {
    this.getTableData()
  },
  methods: {
    query(data) {
      this.agent_name=data.agent_name
      this.phone=data.phone
      this.curPage = 1;
      this.getTableData()
    },
    addAgent() {
      this.$router.push({
        path:'agentOperate'
      })
    },
    currentChange(page) {
      this.curPage = page;
      this.getTableData()
    },
    switchChange(row) {
      agentHandle({
        agent_id:row.agent_id,
        value:row.is_open
      }).then(data=>{
        this.$message.success(data&&data.msg||this.dialog.type+'成功')
      }).catch(err=>{
        console.error(err)
      }).finally(()=>{
        this.getTableData()
      })
    },
    editRow(row) {
      this.$router.push({
        path:'agentOperate',
        query:{
          agent_id:row.agent_id
        }
      })
    },
    getTableData() {
      agentList({
        page: this.curPage,
        limit: 10,
        agent_name: this.agent_name,
        phone: this.phone
      }).then(res => {
        this.tableData = res.data || [];
        this.totalElements = res.count
      })
    }
  }
}
</script>

<style scoped>

</style>
